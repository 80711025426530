import React, {Component} from 'react';
import craftOttawa from './images/craftottawa.jpg';
import adhdYoga from './images/adhdyoga.jpg';
import westernName from './images/westernname.jpg';
import mvcaDPC from './images/mvca-dpc.png';

class Home extends Component {
    render() {
        return (
            <div>
                <div className='container'>
                    <h2>I code things I love {' '}<span role='img' aria-label='red heart'>❤️</span></h2>
                    <h5>A web developer from Eastern Ontario, Canada</h5>
                </div>
                <div className='container'>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="thumbnail">
                                <a href="https://craftottawa.ca" className="site-link">
                                    <img className="website-thumbnail" src={craftOttawa} alt="..."/>
                                    <div className="caption">
                                        <h4 className="">Craft Ottawa</h4>
                                        <div className='centered'>
                                            <code>React/JavaScript, HTML, CSS</code>
                                        </div>
                                        <p className="caption-link-text">The Ottawa Valley is home to a vibrant local craft liquor scene: Find your brewery, distillery, winery, vineyard, cidery, meadery, events, and festivals!</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="thumbnail">
                                <a href="https://adhdyoga.ca" className="site-link">
                                    <img className="website-thumbnail" src={adhdYoga} alt="..."/>
                                    <div className="caption">
                                        <h4 className="">ADHD yoga</h4>
                                        <div className='centered'>
                                            <code>WordPress, HTML, CSS</code>
                                        </div>
                                        <p className="caption-link-text">Online yoga studio membership and private sessions with classes designed around relatable ADHD themes.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="thumbnail">
                                <a href="https://www.westernname.cn" className="site-link">
                                    <img className="website-thumbnail" src={westernName} alt="..."/>
                                    <div className="caption">
                                        <h4 className="">Western Name</h4>
                                        <div className='centered'>
                                            <code>PHP, JavaScript, MySQL, HTML, CSS</code>
                                        </div>
                                        <p className="caption-link-text">The most popular western names for your year of birth, gender, and country. Find perfect names for where you will live, go to school, or do business.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="thumbnail">
                                <a href="https://mvc.on.ca/dpc/" className="site-link">
                                    <img className="website-thumbnail" src={mvcaDPC} alt="..."/>
                                    <div className="caption">
                                        <h4 className="">Mississippi Valley Conservation Authority</h4>
                                        <h5 className="">Daily Planning Cycle (DPC)</h5>
                                        <div className='centered'>
                                            <code>React/JavaScript, API calls, HTML, CSS</code>
                                        </div>
                                        <p className="caption-link-text">Water Resources staff consult this page every morning to plan dam operations.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;