import React, { Component } from 'react';
import Logo from './images/logo-mid-world-technologies.png';
import { Link } from "@reach/router"

class Navigation extends Component {
  render() {
    return (
      <nav className='navbar navbar-expand-md navbar-dark bg-transparent'>
        <Link className='navbar-brand' to='/'>
          <img
            src={Logo}
            width='36'
            height='36'
            className='d-inline-block align-top'
            alt='Mid-World Technologies Globe Logo'
          />{' '}
          Mid-World Technologies
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#collapsibleNavbar'>
          <span className='navbar-toggler-icon' />
        </button>
        <div className='collapse navbar-collapse' id='collapsibleNavbar'>
          <ul className='navbar-nav ml-auto'>
            <Link className='nav-item' to='/'>
              Home
            </Link>
            <Link className='nav-item' to='/contact'>
              <i className='fa fa-fw fa-envelope' /> Contact
            </Link>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navigation;