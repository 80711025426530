import React, {Component} from 'react';
import {Router} from "@reach/router"
import BackgroundImage from './BackgroundImage.js';
import Navigation from './Navigation.js';
import Home from './Home.js';
import Contact from './Contact.js';

class App extends Component {
    render() {
        return (
            <div className='App'>
                <BackgroundImage/>
                <Navigation/>
                <Router>
                    <Home path='/'/>
                    <Contact path='contact'/>
                </Router>
            </div>
        );
    }
}

export default App;