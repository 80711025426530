import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div className='container'>
        <h2>Reach me at</h2>
        <p><a href="mailto:admin@midworldtech.ca">admin@midworldtech.ca</a></p>
      </div>
    );
  }
}

export default Contact;